import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ApexCharts from 'react-apexcharts';

function Dashboard() {
    const [dashboard, setDashboard] = useState(null);
    const [monthlyData, setMonthlyData] = useState([]);
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const baseYear = 2023;

    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + `get-dashboard?year=${selectedYear}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setDashboard(response.data.data);
            setMonthlyData(response.data.monthlyData);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    const yearOptions = [];
    for (let year = new Date().getFullYear(); year >= baseYear; year--) {
        yearOptions.push(year);
    }

    useEffect(() => {
        fetchData();
    },[selectedYear]);

    // Define chart options and data
    const options = {
        chart: {
            type: 'bar'
        },
        colors: ['#ed1b2d', '#ff787a', '#000000'],
        series: [{
            name: 'Tire Installation',
            data: monthlyData.tireRevenue
        }, {
            name: 'ATD Payment',
            data: monthlyData.atdRevenue
        },
        {
            name: 'Total',
            data: monthlyData.totalRevenue
        }
        ],
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
    };

    return (
        
        <div className="container content-area">
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Dashboard</h4>
                                </div>
                            </div>

                            <div className="card-body">
                                <h4 style={{ textAlign: "center", padding: "25px" }} >Appointments</h4>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-6 col-md-12">
                                        <div className="card text-center">
                                            <div className="card-body card-dashboard">
                                                <div className="widget-2 mb-2">
                                                    <i className="fa fa-list text-orange"></i>
                                                </div>
                                                <h6>Pending</h6>
                                                <h3 className="mb-0">{dashboard ? dashboard.pending : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6 col-md-12">
                                        <div className="card text-center">
                                            <div className="card-body card-dashboard">
                                                <div className="widget-2 mb-2">
                                                    <i className="fa fa-spinner text-warning"></i>
                                                </div>
                                                <h6>In Progress</h6>
                                                <h3 className="mb-0">{dashboard ? dashboard.in_progress : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6 col-md-12">
                                        <div className="card text-center">
                                            <div className="card-body card-dashboard">
                                                <div className="widget-2 mb-2">
                                                    <i className="fa fa-check text-success"></i>
                                                </div>
                                                <h6>Completed</h6>
                                                <h3 className="mb-0">{dashboard ? dashboard.complete : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6 col-md-12">
                                        <div className="card text-center">
                                            <div className="card-body card-dashboard">
                                                <div className="widget-2 mb-2">
                                                    <i className="fa fa-undo text-violet"></i>
                                                </div>
                                                <h6>Refunded</h6>
                                                <h3 className="mb-0">{dashboard ? dashboard.refunded : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6 col-md-12">
                                        <div className="card text-center">
                                            <div className="card-body card-dashboard">
                                                <div className="widget-2 mb-2">
                                                    <i className="fa fa-calendar text-primary"></i>
                                                </div>
                                                <h6>Total</h6>
                                                <h3 className="mb-0">{dashboard ? dashboard.total : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 style={{ textAlign: "center", padding: "25px" }} >Monthly Payments ({selectedYear})</h4>

                            <select
                                id="yearSelect"
                                className="form-control"
                                value={selectedYear}
                                onChange={(e) => { setSelectedYear(parseInt(e.target.value)); } }
                                style={{ width: 'fit-content', marginLeft: '10px' }}
                            >
                                {yearOptions.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            <ApexCharts options={options} series={options.series} type="bar" height={350} />
                        </div>
                    </div>
                </div>
            </section >
        </div>
    );
}

export default Dashboard;