import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Components/includes/NavBar";
import TopMenu from "./Components/includes/TopMenu";
import Footer from "./Components/includes/Footer";

import Login from "./Components/Login";
import Users from "./Components/Users";
import Dashboard from "./Components/Dashboard";
import Driver from "./Components/Driver";
import Appointment from "./Components/Appointment";
import Client from "./Components/Client";
import Services from "./Components/Services";
import AdditionalServices from "./Components/AdditionalServices";
import Payment from "./Components/Payment";
import Discount from "./Components/Discount";

import ProtectedRoute from "./Components/ProtectedRoute";

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Dashboard /><Footer /></>} allowedRoles={['SUPERADMIN', 'Admin']} />}
        />
        <Route
          path="/appointments"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Appointment /><Footer /></>} allowedRoles={['SUPERADMIN', 'Admin']} />}
        />
        <Route
          path="/services"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Services /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/additional-services"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><AdditionalServices /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/discounts"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Discount /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/payments"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Payment /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/driver"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Driver /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/users"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Users /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
        <Route
          path="/clients"
          element={<ProtectedRoute element={<><NavBar /><TopMenu /><Client /><Footer /></>} allowedRoles={['SUPERADMIN']} />}
        />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
