import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import jsZip from 'jszip';
window.JSZip = jsZip;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Appointments() {
    const [appointment, setAppointment] = useState(null);
    const [driver, setDriver] = useState(null);
    const [assignDriver, setAssignDriver] = useState({
        driver_id: '',
        delivery_date: '',
        delivery_time: ''
    });
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const [selectedTireDetails, setSelectedTireDetails] = useState(null);

    const openModalForAppointment = (app_id) => {
        setSelectedAppointmentId(app_id);
    };

    const openModalForTireDetails = (tire_Details) => {
        setSelectedTireDetails(tire_Details);
    };


    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-appointments', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setAppointment(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };
    const fetchDriver = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-drivers', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setDriver(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };
    const handleAssignDriver = async (app_id) => {
        try {
            const updatedAssignDriver = {
                ...assignDriver,
                appointment_id: app_id,
            };
            const response = await axios.put(
                process.env.REACT_APP_API_URL + 'appointment-status-update',
                updatedAssignDriver,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }, withCredentials: false
                });
            setAssignDriver({
                driver_id: '',
                delivery_date: '',
                delivery_time: ''
            });
            toast.success(response.data.message);
            setTimeout(() => { }, 2000);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };
    const handleRefund = async (app_id, payment_id) => {
        try {
            const confirmed = window.confirm("Are you sure you want to refund?");
            if (!confirmed) {
                return;
            }
            const response = await axios.post(
                process.env.REACT_APP_API_URL + 'refund',
                { appointment_id: app_id, payment_id: payment_id },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            toast.success(response.data.message);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDriver();
    }, []);

    useEffect(() => {
        if (appointment) {
            const dataTable = new DataTable('#appointmentTable', {
                dom: 'Bfrtip',
                lengthChange: false,
                stateSave: false,
                paging: true,
                scrollX: true,
                buttons: ['print', 'excelHtml5', 'pdfHtml5', 'colvis'],
                columnDefs: [
                    {
                        orderable: false,
                        targets: [0] // Assuming the first column has index 0
                    }
                    // Add more entries for other columns if needed
                ],
            });

            return () => {
                dataTable.destroy(); // Cleanup when component unmounts
            };
        }
    }, [appointment]);
    return (
        <div className="container content-area">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Appointments</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Appointments</h4>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="appointmentTable" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>Appointment#</th>
                                                <th>Assign Driver</th>
                                                <th>Assigned Driver Name</th>
                                                <th>Visit Date Time</th>
                                                <th>ATD Estimated Delivery</th>
                                                <th>Appointment Status</th>
                                                <th>Client Name</th>
                                                <th>Client Address</th>
                                                <th>Client City</th>
                                                <th>Client State</th>
                                                <th>Client Country</th>
                                                <th>Client ZIP</th>
                                                <th>Payment Status</th>
                                                <th>Tire Details</th>
                                                <th>Refund</th>
                                                <th>Created Date Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {appointment && appointment.map((appointment, index) => (
                                                <tr key={index}>
                                                    <td>{appointment.appointment_id}</td>
                                                    <td>
                                                        {appointment.appointment_status_id === 1 && appointment.appointment_status_id !== 0 && appointment.refund === 0 ? (
                                                            appointment.payment_status === 'Complete' ? (
                                                                <button
                                                                    className="btn btn-dark btn-sm"
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModal2"
                                                                    onClick={() => openModalForAppointment(appointment.appointment_id)}
                                                                >
                                                                    Assign Driver
                                                                </button>
                                                            ) : (
                                                                appointment.payment_status === 'Pending' ? (
                                                                    "Payment Pending"
                                                                ) : (
                                                                    ""
                                                                )
                                                            )
                                                        ) : (
                                                            appointment.appointment_status_id === 1 ? (
                                                                "Refund in Progress"
                                                            ) : (
                                                                appointment.appointment_status_id !== 0 ? (
                                                                    "Driver Assigned"
                                                                ) : (
                                                                    ""
                                                                )
                                                            )
                                                        )}
                                                    </td>
                                                    <td>{appointment.driver_name}</td>
                                                    <td>{appointment.delivery_date_time === 'Invalid date' ? "" : appointment.delivery_date_time}</td>
                                                    <td><b>{appointment.estimated_delivery === 'Invalid date' ? "" : appointment.estimated_delivery}</b></td>
                                                    <td className={`status-${appointment.appointment_status_id}`} ><b>{appointment.appointment_status_name}</b></td>
                                                    <td>{appointment.client_name}</td>
                                                    <td>{appointment.address}</td>
                                                    <td>{appointment.city}</td>
                                                    <td>{appointment.state}</td>
                                                    <td>{appointment.country}</td>
                                                    <td>{appointment.zip}</td>
                                                    <td>{appointment.payment_status}</td>
                                                    <td>
                                                        {appointment.payment_status === 'Complete' ? (
                                                            appointment.refund === 1 ? (
                                                                <>
                                                                    <p className="btn btn-success btn-sm" >Refunded</p>
                                                                </>
                                                            ) : (appointment.payment_intent === null ? (
                                                                <>
                                                                    <p className="btn btn-danger btn-sm" >Cannot Refund</p>
                                                                </>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-warning btn-sm"
                                                                    onClick={() => handleRefund(appointment.appointment_id, appointment.payment_id)}
                                                                >
                                                                    Refund Now
                                                                </button>
                                                            )
                                                            )
                                                        ) : appointment.payment_status === 'Pending' ? (
                                                            "Payment Pending"
                                                        ) : null}
                                                    </td>
                                                    <td><button
                                                        className="btn btn-primary btn-sm"
                                                        data-toggle="modal"
                                                        data-target="#tireDetailsModal"
                                                        onClick={() => openModalForTireDetails(appointment.tireDetails)}
                                                    >
                                                        View Details
                                                    </button></td>
                                                    <td>{appointment.created_date_time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModal2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="example-Modal2">Assign Driver</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="FullName">Driver</label>
                                    <select className="form-control" id="driver_id" value={assignDriver.driver_id} onChange={(e) => setAssignDriver({ ...assignDriver, driver_id: e.target.value })}>
                                        <option value="" disabled>Select Driver</option>
                                        {driver && driver.map((driver) => (
                                            <option key={driver.user_id} value={driver.user_id}>{driver.full_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="FullName">Schedule Visit Date</label>
                                    <input type="date" className="form-control" id="delivery_date" value={assignDriver.delivery_date} onChange={(e) => setAssignDriver({ ...assignDriver, delivery_date: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="FullName">Schedule Visit Time</label>
                                    <input type="time" className="form-control" id="delivery_time" value={assignDriver.delivery_time} onChange={(e) => setAssignDriver({ ...assignDriver, delivery_time: e.target.value })} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => handleAssignDriver(selectedAppointmentId)} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="tireDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModal2" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Tire Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* Render the tire details here */}
                            {selectedTireDetails && selectedTireDetails.map((tire, tireIndex) => (
                                <div key={tireIndex} className="card mb-4">
                                    <div className="card-body">
                                        {/* Display tire details */}
                                        <h5 className="card-title">Tire# {tireIndex + 1}</h5>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Year</th>
                                                    <td>{tire.year}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Make</th>
                                                    <td>{tire.make}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Model</th>
                                                    <td>{tire.model}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Trim Option</th>
                                                    <td>{tire.trimoption}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Rim</th>
                                                    <td>{tire.rim}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* Display product details */}
                                        <h6 className="card-subtitle mb-2 ">Product Details:</h6>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Brand</th>
                                                    <th>Tire Description</th>
                                                    <th>Product Number</th>
                                                    <th>Quantity</th>
                                                    <th>Addons</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tire.product_details.map((product, productIndex) => (
                                                    <tr key={productIndex}>
                                                        <td>{product.product_name}</td>
                                                        <td>{product.product_description}</td>
                                                        <td>{product.product_number}</td>
                                                        <td>{product.product_quantity}</td>
                                                        <td>
                                                            <ul>
                                                                {product.addons.map((addon, addonIndex) => (
                                                                    <li key={addonIndex}>{`${addon.name}`}</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {/* Display service details */}
                                        <h6 className="card-subtitle mb-2 ">Services Requested:</h6>
                                        <p>
                                            {tire.service_details.map((service, serviceIndex) => (
                                                <ul>
                                                    <li key={serviceIndex}>{`${service}`}</li>
                                                </ul>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointments;