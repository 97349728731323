import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import jsZip from 'jszip';
window.JSZip = jsZip;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Payments() {
    const [payments, setPayments] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-payments', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setPayments(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (payments) {
            const dataTable = new DataTable('#paymentTable', {
                dom: 'Bfrtip',
                lengthChange: false,
                stateSave: false,
                paging: true,
                scrollX: true,
                buttons: ['print', 'excelHtml5', 'pdfHtml5', 'colvis'],
            });

            return () => {
                dataTable.destroy();
            };
        }
    }, [payments]);
    return (
        <div className="container content-area">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Payments</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Payments</h4>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="paymentTable" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>Payment ID</th>
                                                <th>Appointment#</th>
                                                <th>Client Name</th>
                                                <th>Client Email</th>
                                                <th>Currency</th>
                                                <th>Total Amount</th>
                                                <th>ATD Amount</th>
                                                <th>Service Charges 2%</th>
                                                <th>Discount Amount</th>
                                                <th>Payment Status</th>
                                                <th>Payment Date</th>
                                                {/* <th>View Invoice</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments && payments.map((payment, index) => (
                                                <tr key={index}>
                                                    <td>{payment.transaction_hash}</td>
                                                    <td>{payment.appointment_id}</td>
                                                    <td>{payment.user_name}</td>
                                                    <td>{payment.user_email}</td>
                                                    <td>{payment.currency}</td>
                                                    <td>${payment.total_amount}</td>
                                                    <td>${payment.atd_amount}</td>
                                                    <td>${payment.per_amount}</td>
                                                    <td>${payment.discount_amount}</td>
                                                    <td>{payment.status}</td>
                                                    <td>{payment.created_date_time}</td>
                                                    {/* <td><Link to={`/invoice/${payment.appointment_id}`}>
                                                        View Invoice
                                                    </Link></td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >
            </section >
        </div >
    );
}

export default Payments;