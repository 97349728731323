import React from 'react';
import { NavLink } from 'react-router-dom';

function LeftMenu() {
    const userRole = sessionStorage.getItem('user_role');

    return (
        <div className="sticky" >
            <div className="horizontal-main hor-menu clearfix">
                <div className="horizontal-mainwrapper container clearfix">
                    <nav className="horizontalMenu clearfix">
                        <ul className="horizontalMenu-list">
                            <li>
                                <NavLink to="/dashboard" activeclassname="active">
                                    <i className="fa fa-dashboard"></i> Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/appointments" activeclassname="active">
                                    <i className="fa fa-calendar"></i> Appointments
                                </NavLink>
                            </li>
                            {userRole === "SUPERADMIN" && (
                                <React.Fragment>
                                    <li>
                                        <NavLink to="/driver" activeclassname="active">
                                            <i className="fa fa-user"></i> Drivers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/services" activeclassname="active">
                                            <i className="fa fa-cogs"></i> Services
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/additional-services" activeclassname="active">
                                            <i className="fa fa-cogs"></i> Additional Services
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/payments" activeclassname="active">
                                            <i className="fa fa-money"></i> Payments
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/discounts" activeclassname="active">
                                            <i className="fa fa-percent"></i> Discount
                                        </NavLink>
                                    </li>
                                    <li>
                                        <a href="#" className="sub-icon">
                                            <i className="fa fa-group"></i> Administration{' '}
                                            <i className="fa fa-angle-down horizontal-icon"></i>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <NavLink to="/users" activeclassname="active">
                                                    Admin Users
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/clients" activeclassname="active">
                                                    Clients
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </React.Fragment>
                            )}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default LeftMenu;
