import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import companyLogo from '../logo.png';

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    async function loginButton() {

        if (email !== '' && password !== '') {
            axios.post(process.env.REACT_APP_API_URL + 'login',
                { email, password }, {
                headers: {
                    "Content-Type": "application/json"
                }, withCredentials: false
            }).then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem("user_id", res?.data?.data?.[0].user_id)
                    sessionStorage.setItem("full_name", res?.data?.data?.[0].full_name)
                    sessionStorage.setItem("user_role", res?.data?.data?.[0].user_role)
                    sessionStorage.setItem("email", res?.data?.data?.[0].email)
                    sessionStorage.setItem("access_token", res?.data?.data?.[0].access_token)
                    sessionStorage.setItem("refresh_token", res?.data?.data?.[0].refresh_token)

                    toast.success("Logged in Successfully!!", { pauseOnFocusLoss: false, toastId: "customId" });
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1500);
                }
            }).catch((err) => {
                if (err?.response?.status === 404) {
                    toast.error("User Not Found !!", { pauseOnFocusLoss: false, toastId: "customId" });
                }
                if (err.response?.status === 401) {
                    toast.error("Invalid Credentials", { pauseOnFocusLoss: false, toastId: "customId" });
                }

            })
        }
        else {
            toast.error("Email and Password are required", { pauseOnFocusLoss: false, toastId: "customId" });
        }
    }
    return (
        <div id="app" className="page">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="container">
                    <div className="">
                        <div className="single-page">
                            <div className="">
                                <div className="wrapper wrapper2 card-body">
                                    <form>
                                        <h3 className="text-dark">
                                            <img src={companyLogo} width="300px;" alt="" title="" />
                                        </h3>
                                        <div className="mail">
                                            <input type="text" className="form-control" name='email' id="email" placeholder="Enter Username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="passwd">
                                            <input type="password" name='password' className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <button className="btn btn-primary btn-block" onClick={loginButton} type="button">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div>
    );
}

export default Login;