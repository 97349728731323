import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import jsZip from 'jszip';
window.JSZip = jsZip; // Import the CSS for the confirmation dialog

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Services() {
    const [services, setServices] = useState(null);
    const [newServices, setNewServices] = useState({
        name: '',
        description: '',
        price: '',
    });
    const [editServices, setEditServices] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditServices = (services) => {
        setEditServices(services);
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_API_URL + 'update-services',
                editServices,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            toast.success(response.data.message);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    const handleAddServices = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + 'add-services',
                newServices,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }, withCredentials: false
                });
            setNewServices({
                name: '',
                description: '',
                price: '',
            });
            toast.success(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    const handleDeleteServices = async (service_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this Services?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const response = await axios.delete(
                                process.env.REACT_APP_API_URL + 'delete-services/' + service_id,
                                {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                                    }
                                }
                            );
                            toast.success(response.data.message);
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                            // fetchData();
                        } catch (error) {
                            const errorMessage = error.response.data.message;
                            toast.error(`${errorMessage}`);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-services', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setServices(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (services) {
            const dataTable = new DataTable('#servicesTable', {
                dom: 'Bfrtip',
                lengthChange: false,
                stateSave: false,
                paging: true,
                scrollX: true,
                buttons: ['print', 'excelHtml5', 'pdfHtml5', 'colvis'],
            });

            return () => {
                dataTable.destroy(); // Cleanup when component unmounts
            };
        }
    }, [services]);
    return (
        <div className="container content-area">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Services</h4>
                                </div>
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <button type="button" className="btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModal2" > Add Services </button>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="servicesTable" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {services && services.map((services, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{services.name}</td>
                                                    <td>{services.description}</td>
                                                    <td>{services.price}</td>
                                                    <td>
                                                        {services.service_id === 1 || services.service_id === 9 ? (
                                                            ""
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-warning btn-sm"
                                                                    data-toggle="modal"
                                                                    data-target="#editServicesModal"
                                                                    onClick={() => handleEditServices(services)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => handleDeleteServices(services.service_id)}
                                                                >
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModal2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="example-Modal2">Add Services</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="FullName">Service Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter Name" value={newServices.name} onChange={(e) => setNewServices({ ...newServices, name: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Description">Description</label>
                                    <textarea className="form-control" id="description" placeholder="Enter Description" value={newServices.description} onChange={(e) => setNewServices({ ...newServices, description: e.target.value })} ></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Price">Price</label>
                                    <input type="number" className="form-control" id="price" placeholder="Enter Price" value={newServices.price} onChange={(e) => setNewServices({ ...newServices, price: e.target.value })} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddServices} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            {showEditModal && (
                <div className="modal fade" id="editServicesModal" tabIndex="-1" role="dialog" aria-labelledby="editServicesModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="example-Modal2">Edit Services</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="FullName">Name</label>
                                        <input type="text" className="form-control" id="name" placeholder="Enter Name" value={editServices.name} onChange={(e) => setEditServices({ ...editServices, name: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Description">Description</label>
                                        <textarea className="form-control" id="description" placeholder="Enter Description" value={editServices.description} onChange={(e) => setEditServices({ ...editServices, description: e.target.value })} ></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Price">Price</label>
                                        <input type="number" className="form-control" id="price" placeholder="Enter Price" value={editServices.price} onChange={(e) => setEditServices({ ...editServices, price: e.target.value })} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
}

export default Services;