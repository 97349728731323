import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import jsZip from 'jszip';
window.JSZip = jsZip; // Import the CSS for the confirmation dialog

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Users() {
    const [users, setUsers] = useState(null);
    const [newUser, setNewUser] = useState({
        full_name: '',
        email: '',
        phone_no: '',
        user_role: '',
        password: '',
    });
    const [editUser, setEditUser] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditUser = (user) => {
        setEditUser(user);
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_API_URL + 'update-users',
                editUser,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            toast.success(response.data.message);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    const handleAddUser = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + 'add-users',
                newUser,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }, withCredentials: false
                });
            setNewUser({
                full_name: '',
                email: '',
                phone_no: '',
                user_role: '',
                password: '',
            });
            toast.success(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            fetchData();
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    const handleDeleteUser = async (userId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const response = await axios.delete(
                                process.env.REACT_APP_API_URL + 'delete-users/' + userId,
                                {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                                    }
                                }
                            );
                            toast.success(response.data.message);
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                            // fetchData();
                        } catch (error) {
                            const errorMessage = error.response.data.message;
                            toast.error(`${errorMessage}`);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-users', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setUsers(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (users) {
            const dataTable = new DataTable('#userTable', {
                dom: 'Bfrtip',
                lengthChange: false,
                stateSave: false,
                paging: true,
                scrollX: true,
                buttons: ['print', 'excelHtml5', 'pdfHtml5', 'colvis'],
            });

            return () => {
                dataTable.destroy(); // Cleanup when component unmounts
            };
        }
    }, [users]);
    return (
        <div className="container content-area">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                        <li className="breadcrumb-item text-dark">Administration</li>
                        <li className="breadcrumb-item active" aria-current="page">Admin Users</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Admin Users</h4>
                                </div>
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <button type="button" className="btn btn-primary pull-right" data-toggle="modal" data-target="#exampleModal2" > Add User </button>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="userTable" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone No</th>
                                                <th>Role</th>
                                                <th>Created Date Time</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.full_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.phone_no}</td>
                                                    <td>{user.user_role}</td>
                                                    <td>{user.created_date_time}</td>
                                                    <td><button
                                                        className="btn btn-warning btn-sm"
                                                        data-toggle="modal"
                                                        data-target="#editUserModal"
                                                        onClick={() => handleEditUser(user)}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </button>&nbsp;<button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteUser(user.user_id)}
                                                    >
                                                            <i className='fa fa-trash'></i>
                                                        </button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModal2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="example-Modal2">Add User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="FullName">Fullname</label>
                                    <input type="text" className="form-control" id="full_name" placeholder="Enter Full Name" value={newUser.full_name} onChange={(e) => setNewUser({ ...newUser, full_name: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Email">Email address</label>
                                    <input type="email" className="form-control" id="eamil" placeholder="Enter email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Phone No">Phone Number</label>
                                    <input type="number" className="form-control" id="phone_no" placeholder="Enter Phone no" value={newUser.phone_no} onChange={(e) => setNewUser({ ...newUser, phone_no: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="User Role">User Role</label>
                                    <select
                                        className="form-control"
                                        id="user_role"
                                        value={newUser.user_role}
                                        onChange={(e) => setNewUser({ ...newUser, user_role: e.target.value })}
                                    >
                                        <option value="" disabled>Select User Role</option>
                                        <option value="SUPERADMIN">SUPERADMIN</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Password">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Enter Password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddUser} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            {showEditModal && (
                <div className="modal fade" id="editUserModal" tabIndex="-1" role="dialog" aria-labelledby="editUserModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="example-Modal2">Edit User</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="FullName">Fullname</label>
                                        <input type="text" className="form-control" id="full_name" placeholder="Enter Full Name" value={editUser.full_name} onChange={(e) => setEditUser({ ...editUser, full_name: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Email">Email address (This field cannot be changed)</label>
                                        <input type="email" className="form-control" id="eamil" placeholder="Enter email" value={editUser.email} onChange={(e) => setEditUser({ ...editUser, email: e.target.value })} disabled />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Phone No">Phone Number</label>
                                        <input type="number" className="form-control" id="phone_no" placeholder="Enter Phone no" value={editUser.phone_no} onChange={(e) => setEditUser({ ...editUser, phone_no: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="User Role">User Role</label>
                                        <select
                                            className="form-control"
                                            id="user_role"
                                            value={editUser.user_role}
                                            onChange={(e) => setEditUser({ ...editUser, user_role: e.target.value })}
                                        >
                                            <option value="" disabled>Select User Role</option>
                                            <option value="SUPERADMIN">SUPERADMIN</option>
                                            <option value="Admin">Admin</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
}

export default Users;