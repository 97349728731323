function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="main-footer" style={{ bottom: "0", width: "100%" }}>
            <div className="text-center">
                Copyright &copy; {currentYear}. Developed By<a href="https://www.adaxiomtech.com/" style={{ color: "#ed1b2d" }}> <b>ADAXIOM</b></a>
            </div>
        </footer >
    );
}

export default Footer;