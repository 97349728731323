import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

function ProtectedRoute({ element, allowedRoles }) {
    const accessToken = sessionStorage.getItem('access_token');
    const isLoggedIn = accessToken !== null;

    if (!isLoggedIn) {
        toast.error('Invalid Session', { autoClose: 2000 });
        return <Navigate to="/" />;
    }

    try {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
            toast.error('Session Expired', { autoClose: 2000 });
            return <Navigate to="/" />;
        }

        // Check if the user's role is allowed for this route
        const userRole = decodedToken.user_data.user_role;
        if (!allowedRoles.includes(userRole)) {
            toast.error('Access Denied', { autoClose: 2000 });
            return <Navigate to="/dashboard" />;
        }
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return <Navigate to="/" />;
    }

    return element;
}

export default ProtectedRoute;
