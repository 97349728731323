import companyLogo from '../../logo.png';
import sideLogo from '../../favicon.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function NavBar() {
    const Email = sessionStorage.getItem('email')
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('user_role');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        toast.success("Logged out Successfully", { pauseOnFocusLoss: false, toastId: "customId" });
        setTimeout(() => {
            navigate('/');
        }, 1500);
    };
    let url = "#";
    return (
        <div className="header">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <nav className="navbar navbar-expand-lg main-navbar">
                <div className="container">
                    <a href={url} id="horizontal-navtoggle" className="animated-arrow hor-toggle"><span></span></a>
                    <a className="header-brand" href={url}>
                        <img src={companyLogo} className="header-brand-img" alt="Tire Logo" />
                    </a>
                    <form className="form-inline mr-auto">
                        <ul className="navbar-nav mr-2">
                            <li><a href={url} data-toggle="search" className="nav-link  d-md-none navsearch"><i className="fa fa-search"></i></a></li>
                        </ul>
                    </form>
                    <ul className="navbar-nav navbar-right">
                        <li className="dropdown dropdown-list-toggle d-none d-lg-block">
                            <p className="nav-link nav-link-lg full-screen-link" style={{ color: "#000" }}>Logged in as <b>{Email}</b></p>
                        </li>
                        <li className="dropdown">
                            <a href={url} data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg d-flex">

                                <span><img src={sideLogo} alt="profile-user" className="rounded-circle w-32 mr-2" /></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className=" dropdown-header noti-title text-center border-bottom pb-3">
                                    <img src={companyLogo} alt="Tire Logo" width="60%" />
                                </div>
                                <a className="dropdown-item" href={url} onClick={handleLogout} ><i className="mdi  mdi-logout-variant mr-2"></i> <span>Log Out</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div >
    );
}

export default NavBar;