import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import jsZip from 'jszip';
window.JSZip = jsZip;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Client() {
    const [users, setUsers] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL + 'get-clients', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setUsers(response.data.data);
        } catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(`${errorMessage}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (users) {
            const dataTable = new DataTable('#clientTable', {
                dom: 'Bfrtip',
                lengthChange: false,
                stateSave: false,
                paging: true,
                scrollX: true,
                buttons: ['print', 'excelHtml5', 'pdfHtml5', 'colvis'],
            });

            return () => {
                dataTable.destroy();
            };
        }
    }, [users]);
    return (
        <div className="container content-area">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <section className="section">
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard" className="text-dark">Dashboard</Link></li>
                        <li className="breadcrumb-item"><span className="text-dark">Administration</span></li>
                        <li className="breadcrumb-item active" aria-current="page">Clients</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div className="card">
                            <div className="row">
                                <div className="col-md-6" style={{ padding: "50px" }}>
                                    <h4>Clients</h4>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="clientTable" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone No</th>
                                                <th>Last Appointment</th>
                                                <th>Registeration Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.full_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.phone_no}</td>
                                                    <td>{user.last_appointment}</td>
                                                    <td>{user.created_date_time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div >
    );
}

export default Client;